<template lang="">
  <b-container fluid id="DocEdit">
    <b-row>
      <b-col col-6 class="mb-1">
        <h5>{{this.propYJSDocObj.yjsdocs_name}}</h5>
        <h5>{{this.propYJSDocObj.yjsdocs_code}}</h5>
      </b-col>
      <b-col md="12">
        <h6>{{ editorContent }}</h6>
        <iq-card>
          <textarea
            v-model="editorContent"
            type="text"
            class="form-control textarea"
            style="height: 60vh !important"
            required
          />
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
// import { VueEditor } from "vue2-editor"
import { socialvue } from "../../../config/pluginInit"
import * as Y from "yjs"
import { socket } from "../../../main.js"
import { YJSDocsCollaborate } from "../../../Utils/yjsDocsCollaborate.js"
import { YjsDocs } from "../../../FackApi/api/YjsDocs.js"

export default {
  name: "SpcmEssayEditor",
  created () {
    // Get the document from the database
    this.documentView()
  },
  beforeDestroy () {
    this.provider.yjsdocs.destroy()
    this.ydoc.destroy()
  },
  props: {
    propYJSDocObj: {
      type: Object,
      default: null
    }
  },
  mounted () {
    this.DocName = this.propYJSDocObj
    socialvue.index()
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  data () {
    return {
      customToolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        ["clean"] // remove formatting button
      ],
      editorContent: "",
      ytext: null,
      ydoc: null,
      toastTitle: "Editor",
      showToast: false,
      toastMsg: "",
      toastVariant: "default",
      provider: null
    }
  },
  components: {
    // VueEditor
  },
  methods: {
    /**
     * Load the Document for View & Edit
     */
    async documentView () {
      try {
        let yjsdocsData = await YjsDocs.yjsDocsView(this, this.propYJSDocObj.yjsdocs_id)
        if (yjsdocsData.resp_status) {
          // yjsdocsData = new Uint8Array(yjsdocsData.resp_data.data.yjsdocs)

          // Initialize Yjs document and provider
          this.ydoc = new Y.Doc()

          // Initialising the custom provider to transmit the changes in the Yjs document over websocket protocol
          this.provider = new YJSDocsCollaborate(this, this.ydoc, yjsdocsData.resp_data.data.yjsdocs_id, socket, this.userData.user_id)

          // Start the collaborative editing session
          socket.emit("yjsdocs_join_room_for_collaboration", yjsdocsData.resp_data.data.yjsdocs_id, this.userData.user_id)

          // create the Y.Text instance named yjs_shared_text in the yjs doc
          const ytext = this.ydoc.getText("yjs_shared_text")

          // Bind Yjs content to editorContent
          this.editorContent = ytext.toString()

          // Listen for changes from Yjs and update editorContent
          ytext.observe(event => {
            this.editorContent = ytext.toString()
          })

          // Watch for changes to editorContent and update Yjs document
          this.$watch(
            "editorContent",
            (newValue) => {
              if (ytext.toString() !== newValue) {
                ytext.delete(0, ytext.length) // Clear current Yjs content
                ytext.insert(0, newValue) // Insert new content
              }
            }
          )
        }
      }
      catch (err) {
        console.error("Exception in documentView and err: ", err.message)
      }
    }
  }
}
</script>
<style lang="">
</style>
