/*eslint-disable */
import * as Y from "yjs"

class YJSDocsCollaborate {
  constructor(context, yjsdocs, roomId, socket, userId) {
    this.yjsdocs = yjsdocs;
    this.socket = socket
    this.roomId = roomId;
    this.userId = userId

    // Listen for document updates from the server
    this.socket.on('yjsdocs_sync_doc', (update, yjsdocs_id) => {
      update = new Uint8Array(update) 
      Y.applyUpdate(this.yjsdocs, update);
    });

    // Listen for errors emitted while setting up a collaboration session
    this.socket.on('yjsdocs_join_room_for_collaboration_error', (err) => {
      let errorObj = JSON.parse(err)
      context.toastMsg = errorObj.resp_msg
      context.toastVariant = "danger"
      context.showToast = true
    });

    // When the local document changes, send the update to the server
    this.yjsdocs.on('update', (update) => {
      this.socket.emit('yjsdocs_sync_doc', update, this.roomId, this.userId);
    });
  }
}

export {YJSDocsCollaborate}
